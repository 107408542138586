.navbar {
  height: 97px;
  padding: 0.75em 0.75em 0.75em 2.25em;
  border-bottom: 1px solid #ddd;

  .nav-item {
    font-size: 18px;
    padding: 0.75em;
  }

  .nav-link {
    color: rgb(122, 122, 122) !important;
    font-family: Source Sans Pro, Arial, Helvetica Neue, Helvetica, sans-serif;
  }

  .rules .fa-exclamation-triangle {
    color: #ffcc00;
    font-size: 110%;

    &+.number {
      color: #ffcc00;
    }
  }

  .rules .fa-check-circle {
    color: #30e365;
    font-size: 110%;

    &+.number {
      color: #30e365;
    }
  }

  .sandbox-link {
    color: white !important;
  }

  .rules .fa-sun {
    color: #ffcc00;
    font-size: 110%;
  }
}