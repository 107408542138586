*,
*::before,
*::after {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    background-color: white;
}

.blockquote {
    padding: 60px 80px 40px;
    position: relative;
}

.blockquote p {
    font-family: "Utopia-italic";
    font-size: 35px;
    font-weight: 700px;
    text-align: center;
}

.blockquote:before {
    position: absolute;
    font-family: 'Wide Latin', 'Bookman Old Style', 'Arial';
    top: 0;

    content: "あ";
    font-size: 200px;
    color: rgba(0, 0, 0, 0.1);

}

.blockquote::after {
    content: "";
    top: 20px;
    left: 50%;
    margin-left: -100px;
    position: absolute;
    border-bottom: 3px solid #bf0024;
    height: 3px;
    width: 200px;
}